import React from "react"
import Image from "../components/image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-grid-system';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const portfolio = { 
    "iffo": {"title":"Indisches Online-Festival", "type": "Web application", "url":"https://iffo.indisches-festival.de"},
    "jfco": {"title":"Influenzar COVID-19", "type": "Web application", "url":"https://jugendfilmcamp.online"},
    "rief": {"title":"Franziska Rief", "type": "Website Developement", "url":"https://franziskarief.de"},
    "bam": {"title":"BAM Arendsee", "type": "Website Design", "url":"http://bam-arendsee.de"},
    "profour": {"title":"ProFour", "type": "Website Design", "url":"https://profour.de"},
    "medimind": {"title":"Medimind", "type": "Website Design", "url":"https://medimind.org"},
"flow": { "title": "Flow Institut Freiburg", "type": "Website Design", "url": "http:\/\/flow-institut-freiburg.de" }, 
"jugendfilmpreis": { "title": "Jugendfilmpreis", "type": "Website Design", "url": "http:\/\/jugendfilmpreis.de" }, 
"iff": { "title": "Indisches Filmfestival", "type": "Website Design", "url": "http:\/\/indisches-filmfestival.de" }, 
"schacht": { "title": "Schacht Immobilien", "type": "Website Design", "url": "http:\/\/schacht-immo.de" }, 
"geis": { "title": "Musikwoche Geislingen", "type": "Website Design", "url": "http:\/\/musikwoche-geislingen.de" }, 
"setmanager": { "title": "SetManager", "type": "Web application", "url": "http:\/\/setmanager.de" }, 
"hpae": { "title": "Natuerheilpraxis Englerplatz", "type": "Website Design", "url": "http:\/\/naturheilpraxis-am-englerplatz.de" }, "jfc": { "title": "JugendFilmCamp Arendsee", "type": "Registration system", "url": "http:\/\/jugendfilmcamp.de\/Anmeldeformular" }, "fhps": { "title": "Freie Heilpraktikerschule Freiburg", "type": "Website Design", "url": "http:\/\/freie-heilpraktikerschule.de" }, "tisw": { "title": "Theater im Steinbruch", "type": "Website Design", "url": "http:\/\/theater-im-steinbruch.de" }, "ghse": { "title": "GHSE Schulen", "type": "Website Design", "url": "http:\/\/intranet.ghse.de" }, "redrain": { "title": "RedRain Cinema", "type": "Website Design", "url": "http:\/\/redraincine.com" }, "enok": { "title": "Enno Kastens", "type": "Website Design", "url": "http:\/\/ennokastens.de" }, "dorn": { "title": "Dorn-Therapie", "type": "Website Design", "url": "http:\/\/dorn-praxis.de" }, "rfpm": { "title": "Raum f\u00fcr Pr\u00e4senz", "type": "Website Design", "url": "http:\/\/praesenz.net" }, "gwis": { "title": "Heil-K\u00fcnste", "type": "Website Design", "url": "http:\/\/heil-kuenste.de" } }
console.log(portfolio)


class Page extends React.Component {
    constructor(props) {
        super(props)
        this.state = { visible: false, current_slug: "flow" }
    }
    render() {
        return (
            <Layout container={false}>
                <SEO title="Unsere Kunden" />

                <div className={this.state.visible ? "fullscreen fullscreen-visible" : "fullscreen fullscreen-hidden"}>
                    <img style={{ right: 0, position: "absolute", margin: "40px" }} onClick={() => { this.setState({ visible: false }) }} className="rotate-when-hidden" src={require("../images/portfolio/close.jpg")} />
                    <div style={{ fontSize: "1.4em" }} className="mt-4 text-center">
                        <h1 className="primary">{portfolio[this.state.current_slug].title}</h1>
                        <p class="muted">{portfolio[this.state.current_slug].type}</p>
                    </div>
                    <div className="portfolio-big text-center">
                        <img className="bg" src={require("../images/portfolio/imac.jpg")} />
                        <img className="img" src={require("../images/portfolio/pages/" + this.state.current_slug + "_1080.jpg")} />
                    </div>
                    <p style={{ fontSize: "1.8em" }} class="text-center muted">{portfolio[this.state.current_slug].url.split("//")[1]}</p>
                </div>

                <div className="page-header">
                    <h1 className="text-center mt-4 mb-1">Portfolio</h1>
                    <p className="text-center">Unsere Kunden</p>
                </div>

                <img style={{ width: "100%", position: "absolute" }} src={require("../images/divider/divider2.svg")}></img>

                <Container className="portfolio-container">
                    <Row className="portfolio-row">
                        {
                            Object.keys(portfolio).map(slug => {
                                let site = portfolio[slug]
                                console.log(site)
                                return <Col key={slug} className="portfolio text-center" lg={4}>
                                    <div onClick={() => { this.setState({ visible: true, current_slug: slug }) }} className="portfolio-imac">
                                        <img src={require("../images/portfolio/pages/" + slug + "_350.jpg")} className="portfolio-img" />
                                    </div>
                                    <h2>{site.title}</h2>
                                    <p>{site.type}</p>
                                </Col>

                            })
                        }

                    </Row>
                </Container>

            </Layout>
        )
    }
}

export default Page
